.body {
  font-family: "Poppins", sans-serif;
  width: 100%;
}

.leftContainer {
  background-image: url(../Assets/backgroundLeft.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50% 50%;
  width: 38%;
  height: 100%;
  position: fixed;
}

.rightContainer {
  width: 62%;
  height: 100%;
  position: relative;
  float: right;
  overflow-y: scroll;
}

.leftContent {
  text-align: end;
  margin-right: 30px;
}

.profileImg {
  width: 100px;
  height: 100px;
  object-fit: cover;
  border-radius: 100%;
  margin-top: 15vh;
  margin-bottom: 35px;
}

.introductionTitle {
  font-size: 28px;
  letter-spacing: 1px;
  color: #fff;
}

.highlight {
  color: #fff;
  font-size: 19px;
  display: inline-block;
}

.introductionContent {
  font-size: 16px;
  font-weight: 800;
  letter-spacing: 1px;
  line-height: 1.8;
  color: #ccc;
}

.iconContainer {
  margin-top: 150px;
  margin-right: 10px;
}

.icon {
  color: #ccc;
  display: inline-block;
  margin-left: 27px;
  margin-top: 60px;
  font-size: 27px;
}

.icon:hover {
  color: #fff;
  transition: 0.3s;
}

.copyright {
  margin-top: 20px;
  color: #ccc;
  font-size: 12px;
}

/* Right page */
.resumeDownload {
  margin-top: 2vh;
  text-align: end;
  margin-right: 20px;
}

.resumeLink {
  color: #858585;
  text-decoration: none;
}

.resumeLink:hover {
  color: #858585;
}

.mainTitle {
  margin-top: 10vh;
  font-size: 25px;
  font-family: "Source Sans Pro", sans-serif;
  letter-spacing: 2px;
  color: #858585;
  margin-bottom: 60px;
  text-align: left;
  margin-left: 80px;
}

.mainContent {
  width: 80%;
  color: #858585;
  font-size: 14px;
  letter-spacing: 1px;
  line-height: 2;
  text-align: left;
  margin-left: 80px;
}

.divider {
  width: 80%;
  margin-top: 70px;
  margin-bottom: 60px;
  margin-left: auto;
  margin-right: auto;
  color: #858585;
}

.projectContainer {
  margin-bottom: 50px;
}

.projectTitle {
  font-family: "Source Sans Pro", sans-serif;
  font-size: 25px;
  letter-spacing: 2px;
  color: #858585;
  margin-top: 50px;
  margin-bottom: 20px;
  text-align: left;
  margin-left: 90px;
}

.card {
  width: 85%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 20px;
  filter: grayscale(100%);
  -webkit-filter: grayscale(100%);
  -webkit-transition: all 0.5s ease;
}

.card:hover {
  filter: grayscale(0%);
  filter: gray;
  -webkit-filter: grayscale(0%);
  filter: none;
  transition: 0.5s ease;
}

.cardTitle {
  margin-top: 5px;
  letter-spacing: 1px;
  font-size: 18px;
}

.cardTitle a {
  color: #000;
  text-decoration: none;
}

.cardTitle:hover {
  color: #000;
}

.technologies {
  color: #858585;
  font-size: 15px;
}

.skill {
  width: 100px;
  height: 100px;
  object-fit: cover;
  border-radius: 5px;
  margin-top: 20px;
  margin-bottom: 20px;
  margin-left: 20px;
  margin-right: 20px;
  box-shadow: 0 8px 8px #eeeeee;
  padding: 3px;
}

.skill:hover {
  box-shadow: 0 8px 8px #ccc;
  transition: 0.5s;
}

.footer {
  border-top: 1px solid #ccc;
  width: 80%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 100px;
  margin-bottom: 40px;
}

@media (max-width: 967px) {
  .leftContainer {
    width: 100%;
    position: relative;
  }

  .leftContent {
    text-align: center;
    margin-left: auto;
    margin-right: auto;
  }

  .introductionTitle {
    font-size: 20px;
  }

  .highlight {
    font-size: 13px;
  }

  .introductionContent {
    font-size: 12px;
  }

  .profileImg {
    margin-top: 10vh;
  }

  .icon {
    font-size: 25px;
    margin-right: 15px;
    margin-left: 15px;
    margin-bottom: 35px;
  }

  .iconContainer {
    margin-top: 50px;
  }

  .copyright {
    padding-bottom: 30px;
  }

  .rightContainer {
    width: 100%;
  }

  .resumeLink {
    font-size: 14px;
  }

  .mainTitle {
    font-size: 28px;
    margin-top: 12vh;
    letter-spacing: 0px;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
  }

  .mainContent {
    font-size: 12px;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
  }

  .projectTitle {
    font-size: 25px;
    text-align: center;
    margin-left: 0px;
  }

  .cardTitle {
    font-size: 17px;
  }

  .technologies {
    font-size: 12px;
  }

  .skill {
    width: 55px;
    height: 55px;
    margin-left: 8px;
    margin-right: 8px;
  }
}

@media (max-width: 600px) {
  .mainContent {
    font-size: 10px;
  }
}
